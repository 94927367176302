// If not logged in, redirect to login page

const fb = require('./firebaseUtils')

const handleRoute = () => {
  fb.getIdTokenResult()
    .then(token => {
      // If not logged in, redirect to login page
      if (!token && !window.location.pathname.includes('login')) {
        window.location.replace('/login.html')
      }

      // If going to the login page, but user is logged in, redirect to home page
      if (token && window.location.pathname.includes('login')) {
        window.location.replace('/')
      }

      console.log('js/index.js token: ', JSON.stringify(token))

      const role = token && token.claims && token.claims.role
 
      if (role === 'eventstaff') {
        if (!window.location.pathname.includes('guestArrived.html')) {
      
          window.location.replace('/eventGuestList.html')
        }
      } else if (role === 'agencyadmin') {
        window.location.replace('/agencyMembersList.html')
      } else if (role === 'superadmin' || role === 'admin') {
        window.location.replace('/eventList.html')
      }
    })
    .catch(err => console.error(err))
}
handleRoute()
window.handleRoute = handleRoute

if (module.hot) {
  module.hot.accept(() => {
    window.location.reload()
  })
}
